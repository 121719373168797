import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class CarouselContent {
  public title!: string;
  public imageUrl!: string;
  public content!: string;
  constructor(){}
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  @Input()
  public carouselContent!: CarouselContent[];

  @Input()
  public activeIndex: number = 0;

  @Output()
  public activeIndexChange: EventEmitter<any> = new EventEmitter();

  public currentItem!: CarouselContent;
  private intervalSubscription: any;
  public isPlaying: boolean = true;
  constructor() {
    this.setInterval();    
  }

  private _currentIndex: number = 0;
  public get currentIndex(): number {
    return this._currentIndex;
  }
  public set currentIndex(value: number) {
    this._currentIndex = value;
    this.activeIndexChange.emit(value);
  }
  private setInterval() {
    this.intervalSubscription = setInterval(() => { 
      this.moveForward();
      }, 3000);
  }

  private clearInterval() {
    clearInterval(this.intervalSubscription);
  }

  public togglePlay() {
    if (this.isPlaying) {
      this.clearInterval();
      this.isPlaying = !this.isPlaying;
    } else {
      this.setInterval();
      this.isPlaying = !this.isPlaying;
    }
  }

  public moveForward() {
    if (this.currentIndex === this.carouselContent.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
  }

  public moveSpecific(index: number) {
    this.currentIndex = index;
  }

  public moveBack() {
    if (this.currentIndex === 0) {
        this.currentIndex = this.carouselContent.length - 1;
      } else {
        this.currentIndex--;
      }
  }

  ngOnInit() {
  }


}
