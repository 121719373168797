<div class="content" id="events">
    <h2>Events</h2>
    <div class="event">
        <h4>Fresher’s Day</h4>
        <p>The second year students of our institute gave a warm welcome to the freshers. They tried their level best to entertain the first year students with colourful and nerve-racking dance.This helped the freshers to chisel out their fear and hesitation in the new atmosphere.</p>
    </div>
    <div class="event">
        <h4>Independence Day & Republic Day</h4>
       
        <p>The Independence Day and Republic day were celebrated in a wonderful manner to cherish the victorious moments of the past. Our students performed various activities to induce patriotic feelings in the minds of the viewers.</p>
    </div>
    <div class="event">
        <h4>Teachers Day</h4>
        <p>As teachers are highly rewarded since ancient times,our students celebrated the Teachers Day in our institute to honour their teachers. They expressed their sincere gratitude to their teachers for their laudable service by performing various cultural programmes.</p>
    </div>
    <div class="event">
        <h4>Hostel Day & Chapel Day</h4>
        <p>Our Hostel is ‘a home away from home ’ for our students. So the Hostel Day was celebrated with great pleasure and happiness .It will be cherished in their minds forever. Chapel Day was also celebrated in the Chapel which is in our campus. All the Teaching and Non-teaching staff working in our institute participated and received the blessings of our Almighty Lord.</p>
    </div>
    <div class="event">
        <h4>Christmas Carol</h4>
        <p>The Christmas Carol was conducted in our institute to commemorate the birth of Jesus Christ. Our students enacted a skit depicting the ‘Manger scene’. It was really a remarkable and splendid moment which will be cherished in the minds of the students forever.</p>
    </div>
    <div class="event">
        <h4>Annual Day Cum Sports Meet</h4>
        <p>The Annual Day cum sports Day was celebrated in our institute. Our pastorate chairman  presided over the function . Mrs.Jebaselvi (B.Ed College Principal-Sattankulam ) was the chief guest on that day. He gave a notable address to the students. Students who excelled in their academics and sports were awarded medals and prizes. It was really a memorable and remarkable day for our students</p>
    </div>
</div>
