<div class="content" id="course-detail">
    <h2>Course Detail</h2>
    <div class="course-detail">
        <h4>Diploma in Elementary Education</h4>
        <table>
            <tr>
                <td>Duration</td>
                <td>Two Years</td>
            </tr>
            <tr>
                <td>Annual  Intake</td>
                <td>100 students</td>
            </tr>
            <tr>
                <td>Counselling</td>
                <td>50 students</td>
            </tr>
            <tr>
                <td>Management</td>
                <td>50 students</td>
            </tr>
        </table>
    </div>
    <div class="course-detail">
        <h4>Fees Structure</h4>
        <table>
            <tr>
                <td>Admission Fees</td>
                <td>Rs.3500/-</td>
            </tr>
            <tr>
                <td>Special Fees</td>
                <td>Rs.1500/-</td>
            </tr>
        </table>
    </div>
    <div class="course-detail">
        <h4>Library</h4>
        <table>
            <tr>
                <td class="large-content">Number of books in the library</td>
                <td>9274</td>
            </tr>
            <tr>
                <td class="large-content">Total Number of educational journal/periodicals being subscribed</td>
                <td>8</td>
            </tr>
            <tr>
                <td class="large-content">Number of Encyclopedias available in the Library</td>
                <td>73</td>
            </tr>
            <tr>
                <td class="large-content">Number of books available in the reference section of the library</td>
                <td>141</td>
            </tr>
            <tr>
                <td class="large-content">Seating Capacity of the reading room of the library</td>
                <td>50 students</td>
            </tr>
        </table>
    </div>
</div>
