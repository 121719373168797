import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ActivityComponent } from './activity/activity.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { EventComponent } from './event/event.component';
import { FacultyComponent } from './faculty/faculty.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'events', component: EventComponent },
  { path: 'activities', component: ActivityComponent },
  { path: 'infrastructure', component: InfrastructureComponent },
  { path: 'faculty', component: FacultyComponent },
  { path: 'album', component: GalleryComponent },
  { path: 'about', component: AboutComponent },
  { path: 'course-detail', component: CourseDetailComponent },
  { path: 'home/about', component: AboutComponent },
  { path: 'home/course-detail', component: CourseDetailComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
