<div id="infrastructure">
    <h2>Infrastructure</h2>
    <div class="facility">
        <h4>Laboratories</h4>
        <p>To augment effective learning, the institute contains all laboratories like; Computer Lab, Science Lab, Psychology lab, Social Lab, Maths Lab, Modern Language laboratory etc.</p>
    </div>
    <div class="facility">
        <h4>Library</h4>
        <p>The library comprises numerous books, periodicals, Educational Journals, Encyclopedia to make references in all subjects. Internet access is also provided to widen knowledge</p>
    </div>
    <div class="facility">
        <h4>Teaching Fraternity</h4>
        <p>This institute has been continuously blessed with devoted teachers who have a keen sense of duty and inexhaustible capacity for hard work and working round the clock for imparting education and character development of the youngsters under their care.</p>
    </div>
    <div class="facility">
        <h4>Hostel Facilities – “A home away from Home”</h4>
        <p>We have a Hostel with all the facilities inside the campus. The hostel is well protected by the boundary walls and the boarders are affectionately taken care by the warden and the Principal. Hygienic mess is functioning in order to serve nutritious and tasty (Veg & Non-Veg) food. Purified drinking water facility is provided. St.Luke’s Hospital, a renowned hospital in Southern Districts is adjacent to meet medical exigency.</p>
    </div>
</div>
