<div class="carousel">
    <button class="carousel__slide-left" (click)="moveBack()">
    </button>
    <div class="carousel__wrapper">
      <ng-content></ng-content>
      
      <div class="carousel__control">
        <button class="pause" (click)="togglePlay()"
          [innerHTML]="isPlaying ? 'Pause': 'Play'"></button>
        <span *ngFor="let slidePoint of carouselContent; index as i" [class.active]="currentIndex===i"
          (click)="moveSpecific(i)"
        ></span>
      </div>
    </div>
    <button class="carousel__slide-right" (click)="moveForward()">   
    </button>
  </div>