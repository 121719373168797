<div id="faculty">
  <h2>Faculty Members</h2>
  <div class="flex-box-row btmspace-50">
    <div class="one_half flex-column">
      <div class="image">
        <img src="./assets/faculty/Correspondent.jpg" alt="">
      </div>
      <ul>
        <li>Mr.I. Rajasekar Jebarathinam ,B.A.</li>
        <li>Correspondent</li>
      </ul>
    </div>
    <div class="one_half flex-column">
      <div class="image">
        <img src="./assets/faculty/Principal.jpg" alt="">
      </div>
      <ul>
        <li>Mrs.S.Lydia Gracemani, M.Sc.,M.Ed.,M.Phil.,</li>
        <li>Principal</li>
        <li>DOJ:30-08-2010</li>
      </ul>
    </div>

  </div>
  <h4>Teachers Educators</h4>
  <div class="flex-box-row btmspace-50">
    <div class="one_third flex-column">
      <div class="image">
        <img src="./assets/faculty/Malar.jpg" alt="">
      </div>
      <ul>
        <li>Mrs.R.Gnanamalar, M.A.,M.Ed.,M.Phil.,</li>
        <li>English</li>
        <li>DOJ:02-06-2009</li>
      </ul>
    </div>
    <div class="one_third flex-column">
      <div class="image">
        <img src="./assets/faculty/Shanthi.jpg" alt="">
      </div>
      <ul>
        <li>Mrs.Shanthi Glory Ponrani, M.Sc.,M.Ed.,M.Phil.</li>
        <li>Mathematics</li>
        <li>DOJ:17-08-2012</li>
      </ul>
    </div>
  </div>
  <div class="flex-box-row btmspace-50">

    <div class="one_half flex-column">
      <div class="image">
        <img src="./assets/faculty/JeyaGnanaselvi.jpg" alt="">
      </div>
      <ul>
        <li>Mrs. R. Jeya Gnanaselvi, M.A.,M.Ed.</li>
        <li>Tamil</li>
        <li>DOJ:05-05-2022</li>
      </ul>
    </div>
    <div class="one_half flex-column">
      <div class="image">
        <img src="./assets/faculty/Brighty.jpg" alt="">
      </div>
      <ul>
        <li>Mrs.K.Brighty Juliet Jebakumari, M.A.,M.P.Ed.</li>
        <li>Physical Directress</li>
        <li>DOJ:14-12-2010</li>
      </ul>
    </div>
  </div>
  <h4>Administrative Staff</h4>
  <div class="flex-box-row btmspace-50">
    <div class="one_third flex-column">
      <div class="image">
        <img src="./assets/faculty/Suganthi.jpg" alt="">
      </div>
      <ul>
        <li>Miss.D.Suganthi Mercy</li>
        <li>Junior Asst.</li>
        <li>DOJ:03-07-2013</li>
      </ul>
    </div>
    <div class="one_third flex-column">
      <div class="image">
        <img src="./assets/faculty/Anandakumar.jpg" alt="">
      </div>
      <ul>
        <li>Mr.C.Anandakumar</li>
        <li>Lab-cum-workshop Assistant</li>
        <li>DOJ:07-03-2013</li>
      </ul>
    </div>
    <div class="one_third flex-column">
      <div class="image">
        <img src="./assets/faculty/Jeyasingh.jpg" alt="">
      </div>
      <ul>
        <li>Mr.Jeyasingh</li>
        <li>Night Watchman</li>
        <li>DOJ:01-11-2003</li>
      </ul>
    </div>
  </div>
</div>
