<!-- ##############################   Slide show   ############################################## -->
<app-carousel [carouselContent]="carouselContent" [(activeIndex)]="activeIndex">
  <div class="carousel__inner" *ngFor="let currentContent of carouselContent; index as i" [class.active]="activeIndex===i">
      <div class="carousel__inner__header"
        [innerHTML]="currentContent?.title"> </div>
      <div class="carousel__inner__image-article">
        <div class="carousel__inner__image">
          <img [src]="currentContent?.imageUrl" alt="" />
        </div>
        <div class="carousel__inner__article"
          [innerHTML]="currentContent ?.content"></div>
      </div>
    </div>
</app-carousel>


    <div class="home-content">
      <section id="introblocks">
      
        <h2 class="notification">Application for year 2024 - 2025 - <a href="https://forms.gle/8Ma78ZZ8X2HTSpnJA" target="_blank">Click here to apply</a></h2>
     
      <!-- ################################################################################################ -->
      <h4>Links</h4>
      <!-- ################################################################################################ -->
      <div class="flex-box-row wrap btmspace-15 links">
        <div class="one_quarter"><a href="./assets/docs/Recogniton_order.pdf" target="_blank">Recognition</a></div>
        <div class="one_quarter"><a href="./assets/docs/Recogniton_order1.pdf" target="_blank">Recognition 1</a></div>
        <div class="one_quarter"><a href="./assets/docs/Recogniton_order2.pdf" target="_blank">Recognition 2</a></div>
        <div class="one_quarter"><a routerLink="course-detail">Course Details</a></div>
      </div>
      <div class="flex-box-row btmspace-50">
        <div class="one_quarter "><a href="./assets/docs/Students_Approval_21-22.pdf" target="_blank">List of candidate selected 2021-2022</a></div>
        <div class="one_quarter"><a href="./assets/docs/Students_Approval_22-23.pdf" target="_blank">List of candidate selected 2022-2023</a></div>
        <div class="one_quarter "><a href="./assets/docs/Mandatory_Disclosure_format.pdf" target="_blank">Mandatory Disclosure format</a></div>
      </div>
           
      <ul class="nospace flex-box-row introblocks">
        <li class="one_third first">
          <article><i class="fa fa-user"></i>
            <h6 class="heading font-x1"><a routerLink="about">About Us</a></h6>
            <p>St. John’s Teacher Training Institute has earned a distinguished place in the annals of History of Tamilnadu.
              This premier institution had its humble origin in the year 1877.[<a href="#">&hellip;</a>]</p>
          </article>
        </li>
        <li class="one_third">
          <article><i class="fa fa-linode"></i>
            <h6 class="heading font-x1"><a href="#">Our Motto</a></h6>
            <p>
              Our motto is “ To love is To serve” .So the teacher trainees of our institute are trained to love their fellow beings 
              and advised to , ‘go out to serve our country and countrymen’.
            </p>
          </article>
        </li>
        <li class="one_third">
          <article><i class="fa fa-star"></i>
            <h6 class="heading font-x1"><a routerLink="course-detail">Course Detail</a></h6>
            <p>Diploma in Elementary Education details and other infrastructure details</p>
          </article>
        </li>
      </ul>
    </section>
    <div class="clear"></div>
    </div>
    
  
