import { Component } from '@angular/core';
import { CarouselContent } from '../carousel/carousel.component';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public activeIndex: number = 0;
  public carouselContent: CarouselContent[] = [
    {
      title: '',//`Our motto is “ To love is To serve” .So the teacher trainees of our institute are trained to love their fellow beings and advised to , ‘go out to serve our country and countrymen’',`,
      content: '',
      imageUrl: './assets/slider/img1.jpg'
    },
    {
      title: '', //`Our motto is “ To love is To serve” .So the teacher trainees of our institute are trained to love their fellow beings and advised to , ‘go out to serve our country and countrymen’`,
      content: '',
      imageUrl: './assets/slider/img2.jpg'
    },
    {
      title: '', // `Our motto is “ To love is To serve” .So the teacher trainees of our institute are trained to love their fellow beings and advised to , ‘go out to serve our country and countrymen’`,
      content: '',
      imageUrl: './assets/slider/img3.jpg'
    },
    {
      title: '', // `Our motto is “ To love is To serve” .So the teacher trainees of our institute are trained to love their fellow beings and advised to , ‘go out to serve our country and countrymen’`,
      content: '',
      imageUrl: './assets/slider/img4.jpg'
    },
    {
      title: '', // `Our motto is “ To love is To serve” .So the teacher trainees of our institute are trained to love their fellow beings and advised to , ‘go out to serve our country and countrymen’`,
      content: '',
      imageUrl: './assets/slider/img5.jpg'
    }];


}
