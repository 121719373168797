<div id="gallery">
  <h2>Album</h2>
  <ul class="flex-box-row wrap image-list">
    <li class="one_quarter"><a href="#"><img src="./assets/album/2_events_sport_day.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc_1333.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06495.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06557.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06663.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06674.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06685.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06717.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/dsc06846.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/events7_awareness_programme.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/events5_life_skill.jpg" alt=""></a></li>
    <li class="one_quarter"><a href="#"><img src="./assets/album/events3tree_plantation.jpg" alt=""></a></li>
  </ul>

</div>
<nav class="pagination">
  <ul>
    <li><a href="#">&laquo; Previous</a></li>
    <li><a href="#">1</a></li>
    <li><a href="#">2</a></li>
    <li><strong>&hellip;</strong></li>
    <li><a href="#">6</a></li>
    <li class="current"><strong>7</strong></li>
    <li><a href="#">8</a></li>
    <li><a href="#">9</a></li>
    <li><strong>&hellip;</strong></li>
    <li><a href="#">14</a></li>
    <li><a href="#">15</a></li>
    <li><a href="#">Next &raquo;</a></li>
  </ul>
</nav>

<!-- / main body -->
