<div class="content" id="activities">
    <h2>Activities</h2>
    <div class="activity">
        <h4>Old Girl’s Association</h4>
        <p>The Old Girl’s Association Meeting was held to  bridge the gap between the youngsters and the aged ones. The younger generations took the senior citizens to the world of entertainment.</p>
     </div> <div class="activity">  
        <h4>Educational Tours</h4>
        <p>Learning takes place better by seeing than by hearing. The students and the teachers go on an educational tour to Ramanathapuram . The visited the historical places Dhaneskodi ,Pamban bridge ,Dr.A.P.J.Abdul Kalam’s Memorial Marine Fisheries and a mall .It was really a remarkable tour to our students .It will be cherished in their mind forever.</p>
        </div>  <div class="activity">  
        <h4>Practice Teaching</h4>
        <p>Students are given sufficient practice in teaching to improve their confidence. The institute arranges observation, Teaching Practice and project work mostly in reputed schools in and around Nazareth ..We sincerely thank the Headmasters, Headmistress and teachers of the respective schools who give their whole hearted co-operation to sharpen the qualities and teaching efficiency of our students. . High quality of teaching practice is undertaken by the students.</p>
    </div> <div class="activity">   
        <h4>Parent-Teacher Association</h4>
        <p>Parent –Teacher Association Meetings are held every now and then .Parents give their whole hearted co-operation and support for the development of the institute. In our institute we give intensive training to youngsters and mould them into good teachers, who in turn, move out to different parts of India and work in schools imparting the light of knowledge to tiny tots, We earnestly solicit co-operation, help and prayers from the people in turning out of good teachers from our training institute.</p>
    </div> <div class="activity">  
        <h4>SEBI Financial Education Workshop</h4>
        <p>SEBI Financial Education Workshop was conducted in our institute.Mr. Vijayakumar , Resource person of SEBI conducted the programme and created awareness among our students and staff to start small scale Industries and how to save money in future.</p>
    </div> <div class="activity">   
        <h4>Road Safety Awareness Programme</h4>
        <p>Road Safety Awareness Programme was conducted in institute. He gave clear idea about road safety and its consequences was described elaborated by</p>
    </div> <div class="activity">   
        <h4>World Population Day</h4>
        <p>The World Population Day was organized . Dr. Vijayakumar and Dr.Saravanan gave a brief feature about population explosion . Group discussion was conducted and suggestions were given to the students to put an end to this problem. Elocution competition also conducted.</p>
    </div> <div class="activity">   
        <h4>World Aids Day</h4>
        <p>World AIDS Day was celebrated in our institute on December 1st . The students as well as the staff took an oath to create a world without AIDS.</p>
    </div> <div class="activity">  
        <h4>Legal Aid Movement Day</h4>
        <p>Legal Aid Movement Day was celebrated in our institute under the presidentship of Hon’ble Thru.S.Padmanaban B.A.M.L. Sub Judge District services Authority . The Thoothukudi members of the ‘ Bar Association , Srivaikuntam participated in the programme and made a grand success.</p>
    </div> <div class="activity">  
        <h4>T.B. Awareness Programme </h4>
        <p>T.B. Awareness Programme was conducted in our institute to create awareness about the disease and the preventive measures to be taken . Mr.Immanuel , Resource person gave a lecture.</p>
    </div> <div class="activity">   
        <h4>Swachh Bharat Abhiyan</h4>
        <p>Swatchh Bharat plan was celebrated in our institute. Many competitions like Essay writing, Speech and poem writing were conducted and prizes were distributed</p>
    </div> <div class="activity">  
        <h4>Science Week </h4>
        <p>Science week was celebrated for one week in our institute. Students conducted physical science and Biological science experiments with the guidance of the Science lecturer.</p>
    </div> <div class="activity">  
        <h4>Yoga</h4>
        <p>The students of our institute are undergoing Yoga practice for 20 weeks during this academic year. The opening day for Yoga Practice was celebrated .</p>
    </div> <div class="activity"> 
        <h4>N.S.S. Camp</h4>
        <p>N.S.S. camp was conducted for our students for one week at the nearby village Agappaikulam . Our students created Dengue awareness to the villagers and advised them to keep the surroundings clean.</p>
    </div> <div class="activity">   
        <h4>Medical Camp</h4>
        <p>Medical Camp was conducted in our institute for two days . Our Principal Mrs.S.Lydia Gracemani inaugurated the camp. Dr. Haripriya conducted medical check up. All the students participated in the camp.</p>
    </div> <div class="activity">   
        <h4>Guide’s Camp</h4>
        <p>Guide’s camp was conducted in the premises of the institute .Mr. Avudaiappan the well qualified Guides Masters organized the camp. They initiated service mind in the minds of the students to serve the sick,poor and downtrodden of the country. All the students of our institute participated in all the activities enthusiastically. It was really a memorable experience for them.</p>
    </div> <div class="activity">    
        <h4>Other activities</h4>
        <p>A seminar on protection of children from sexual offences was conducted . Our principal Mrs.S.Lydia Gracemani presided over the function and gave an elaborate lecture about it.          </p>
        <p>District Level Competition about “ Youth Contribution towards HIV and AIDS awareness ” was conducted by DIET, Vanaramutti . Two of our students participated in the competitions conducted . Miss.Sagunthala Devi won the first prize in slogan writing. Miss.Selva Meena won the Second prize in painting.</p>
    </div>
</div>
