<header> 
  <div class="header-content">
    <div id="logo">
        <a href=""><img src="./assets/logo.png"></a>
    </div>
    <div id="quickinfo">
      <div class="ncte">
      <h4>Recognised By</h4> 
      <a href="https://ncte.gov.in" target="_blank">
        <img src="./assets/ncte.png" >
      </a>
      </div>
      <ul class="nospace">
        <li><strong>Email</strong>
          stjohnsnaz@yahoo.com</li>
        <li><strong>Contact</strong>
          04639 - 277341</li>
      </ul>
    </div>
  </div>
  <nav class="menu"> 
    <ul>
      <li class="active"><a routerLink="home">Home</a></li>
      <li><a  routerLink="events">Events</a>
        <!-- <ul>
          <li><a href="#">Gallery</a></li>
          <li><a href="#">Full Width</a></li>
          <li><a href="#">Sidebar Left</a></li>
          <li><a href="#">Sidebar Right</a></li>
          <li><a href="#">Basic Grid</a></li>
        </ul> -->
      </li>
      <li><a routerLink="activities">Activites</a>
        <!-- <ul>
          <li><a href="#">Level 2</a></li>
          <li class="dropdown"><a  href="#">Level 2 + Drop</a>
            <ul>
              <li><a href="#">Level 3</a></li>
              <li><a href="#">Level 3</a></li>
              <li><a href="#">Level 3</a></li>
            </ul>
          </li>
          <li><a href="#">Level 2</a></li>
        </ul> -->
      </li>
      <li><a routerLink="infrastructure">Infrastructure</a></li>
      <li><a routerLink="faculty">Faculty Members</a></li>
      <li><a routerLink="album">Album</a></li>
      <li><a routerLink="about">About Us</a></li>
    </ul>
  </nav>
</header>


<router-outlet></router-outlet>

<footer class="row4"> 
  <div class="footer-content">
    <!-- ################################################################################################ -->
    <div class="one_quarter first">
      <h6 class="heading">Address</h6>
      <ul class="nospace btmspace-30 linklist contact">
        <li><i class="fa fa-map-marker"></i>
          <address>
            St.John's Teacher Training Institute, <br>
            Nazareth, <br>
            Thoothukudi District <br>
          </address>
        </li>
        <li><i class="fa fa-phone"></i>04639 - 277341</li>
        <li><i class="fa fa-envelope-o"></i>stjohnsnaz@yahoo.com</li>
      </ul>
    </div>
    <!-- <div class="one_quarter">
      <h6 class="heading">Lorem ipsum dolor</h6>
      <ul class="nospace linklist">
        <li><a href="#">Ipsum eu urna tristique</a></li>
        <li><a href="#">Tempor nunc aliquet</a></li>
        <li><a href="#">Ipsum sit amet mollis</a></li>
        <li><a href="#">Ullamcorper odio velit</a></li>
        <li><a href="#">Vulputate magna at rhoncus</a></li>
      </ul>
    </div>
    <div class="one_quarter">
      <h6 class="heading">Lorem ipsum dolor</h6>
      <ul class="nospace linklist">
        <li><a href="#">Turpis diam eget quam in</a></li>
        <li><a href="#">Blandit sed nulla id tempor</a></li>
        <li><a href="#">Duis interdum ligula at</a></li>
        <li><a href="#">Lectus venenatis blandit</a></li>
        <li><a href="#">Nulla molestie tellus</a></li>
      </ul>
    </div> -->
    <div class="one_quarter">
      <h6 class="heading">Contact Us</h6>
      <p class="nospace btmspace-15">Please reach us through the phone number and email address</p>
      <form method="post" action="#">
        <fieldset>
          <legend>Newsletter:</legend>
          <input class="btmspace-15" type="text" value="" placeholder="Name">
          <input class="btmspace-15" type="text" value="" placeholder="Email">
          <textarea style="background-color: #000;" name="content" id="content" cols="35" rows="3" placeholder="content"></textarea>
          <button type="submit" value="submit">Submit</button>
        </fieldset>
      </form>
    </div>
    <!-- ################################################################################################ -->
  </div>
 

  <div id="copyright" class="row5"> 
    <!-- ################################################################################################ -->
    <p>Copyright &copy; 2021 - All Rights Reserved - ST. John's Teacher Training Institute </p>
    <p></p>
    <!-- ################################################################################################ -->
  </div>
</footer>
<a id="backtotop" href="#top"><i class="fa fa-chevron-up"></i></a>