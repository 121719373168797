<div id="about-us">
  <h2>About Us</h2>
  <p>St. John’s Teacher Training Institute has earned a distinguished place in the annals of the History of Tamilnadu.
    This premier and Government aided institution had its humble origin in the year 1877. The Recognition order of our
    Institute is SRO-96/NCTE/2150 dt 27-11-1996 ,F.TN-Ele-33/SRO/NCTE/2000-2001/891 Dt 07-07-2000,F.SRO/NCTE/2002-
    2003/2632, F.SRO/NCTE/TN/2004-05/1586.</p>

  <p>The dynamic missionary Rev.Canon Arthur Margoschis founded this esteemed institution with 18 students with a broad
    vision of producing hundreds of high standard teachers.We should thank God for the long line of devoted missionary
    educationists like Miss.Herring, Miss.E.M.Groves, Miss.Green, Miss.L.M.I.Evans, Miss.G.M.Harris and
    Miss.E.M.Swingler who served this institution from its inception.By their wisdom,sacrifice, commitment,simplicity
    and love these great missionaries rendered remarkable service to this great old institution.</p>

  <p>As a result of their noble and untiring service ,this pioneer institute started functioning as a Lower Grade
    Training School from 1877 to 1900. In 1900, it was upgraded into Higher Grade Training School. In the year 1956, the
    Secondary Grade Training School started functioning with a Model School attached to it. It became a Basic Training
    School in the Year 1957. From 1973 it trained candidates for Diploma in Teacher Education. At present it has been
    training candidates for Diploma in Elementary Education with a strong determination. Thousands of young women have
    passed through this key institution as trained teachers and many of them are employed all over the country.</p>

  <p>At present, this prestigious institute has flourished and prospered in all spheres. It has been producing very high
    percentage of result every year .As it has been imparting quality education with high standards and excellent
    discipline, 116 students of our institute have passed the Teacher Eligibility Test conducted by the Government of
    Tamilnadu.</p>
  <p>As Almighty has showered His blessings upon this illustrious institute it is considered to be one of the reputed
    training institutes in the South. All the wishes and desires of its founder Rev.Canon Arthur Margoschis have been
    fulfilled. Hope that God will continue to bless the work of the institute in the years ahead.</p>

  <p>As Almighty has showered His blessings upon this illustrious institute it is considered to be one of the reputed
    training institutes in the South. All the wishes and desires of its founder Rev.Canon Arthur Margoschis have been
    fulfilled. Hope that God will continue to bless the work of the institute in the years ahead.</p>
</div>
